.App {
  text-align: left;
  color: #162A38;
  max-width: 1800px;
}

a {
  color: #61dafb;
  text-decoration: none;
}

h1, h2, h3 {
  margin: 0;
}

p {
  margin: 0;
}

button {
  padding: 0;
}

/* hide numeric input's spinners */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

/* hide datalist's arrow shown on focus by default */
input:not([type="date"])::-webkit-calendar-picker-indicator {
  opacity: 0;
}

/* TODO: refactor using material ui (MUI) theme or props */
 
/* override default MUI tooltip rules */
.MuiTooltip-tooltip {
  color: rgba(255, 255, 255, 1) !important;
  background-color: #162a38 !important;
}

/* override default MUI tooltip arrow rules */
.MuiTooltip-arrow {
  color: #162a38 !important;
  overflow: visible !important;
}
